import { formatAmount } from '@metaswiss/lib';
import { BaseCard, Button, Text, ThemedIcon } from '@metaswiss/ui-kit';
import CircleBarChart from '@metaswiss/ui-kit/src/components/atoms/bar-chart/BarChart.tsx';
import { CoinsDouble } from '@metaswiss/ui-kit/src/iconography/CoinsDouble.tsx';
import { CurrencyChfIcon } from '@metaswiss/ui-kit/src/iconography/CurrencyChfIcon.tsx';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation.ts';
import { routes } from '../../../../../../router/routes.ts';
import { getChartPalette } from '../../../../../../shared/helpers/getChartColor.helper.ts';

import {
  BondCardHeader,
  BondInfoContainer,
  BondInfoWrapper,
  BondPriceInfoContainer,
  BondsInfoElementsContainer,
  BondsRemainingContainer,
  ButtonsWrapper,
  ChartWrapper,
  CountryFlag,
  FlagHeaderContainerWrapper,
  FlagWrapper,
  HeaderContainer,
  IconWrapper,
  PriceInfoWrapper,
  PricePerBondContainer,
} from './shareCard.styles.ts';
import { ShareCardProps } from './types.ts';

export const ShareCard: FC<ShareCardProps> = ({ share, onClick, handleInvestButtonClick }) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const navigate = useNavigate();

  return (
    <BaseCard
      padding="0rem"
      disableStateEffects
      onClick={onClick}
      borderBox
      override={{
        borderRadius: theme.v2.radius.small,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
      }}
    >
      <HeaderContainer>
        <BondCardHeader>
          <Text fontSize={'base'} fontWeight={'bold'} lineHeight={'base'} color={theme.v2.text.headingPrimary}>
            {share.issuer.issuerName}
          </Text>
          <FlagHeaderContainerWrapper>
            <Text fontSize={'base'} fontWeight={'reg'} lineHeight={'base'} color={theme.v2.text.headingPrimary}>
              {share.issuer.country.name}
            </Text>
            <FlagWrapper>
              <CountryFlag src={`../../../assets/flags/${(share.issuer.country.code as string).toLowerCase()}.svg`} />
            </FlagWrapper>
          </FlagHeaderContainerWrapper>
        </BondCardHeader>
      </HeaderContainer>
      <BondInfoContainer>
        <BondInfoWrapper>
          <BondsInfoElementsContainer>
            <BondPriceInfoContainer>
              <PricePerBondContainer>
                <IconWrapper>
                  <ThemedIcon icon={CurrencyChfIcon} size="medium" customStrokeColor={theme.v2.icon.default} />
                </IconWrapper>
                <PriceInfoWrapper>
                  <Text fontSize={'xsm'} fontWeight={'reg'} lineHeight={'extraSmall'} color={theme.v2.text.disabled}>
                    {textTranslation('offering.pricePerShare')}
                  </Text>
                  <Text fontSize={'base'} fontWeight={'bold'} lineHeight={'base'} color={theme.v2.text.headingPrimary}>
                    {share.pricePerShare}
                  </Text>
                </PriceInfoWrapper>
              </PricePerBondContainer>
              <PricePerBondContainer>
                <IconWrapper>
                  <ThemedIcon icon={CoinsDouble} size="large" customStrokeColor={theme.v2.icon.default} />
                </IconWrapper>
                <PriceInfoWrapper>
                  <Text fontSize={'xsm'} fontWeight={'reg'} lineHeight={'extraSmall'} color={theme.v2.text.disabled}>
                    {textTranslation('offering.minimalInvestment')}
                  </Text>
                  <Text fontSize={'base'} fontWeight={'bold'} lineHeight={'base'} color={theme.v2.text.headingPrimary}>
                    {formatAmount(Number(share.minimalInvestment))}
                  </Text>
                </PriceInfoWrapper>
              </PricePerBondContainer>
            </BondPriceInfoContainer>
            <BondsRemainingContainer>
              <ChartWrapper>
                <CircleBarChart
                  value={share.availableSharesInRound}
                  total={share.totalSharesInRound}
                  size={'3.75rem'}
                  fontSize={'base'}
                  color={getChartPalette(share.totalSharesInRound, share.availableSharesInRound)}
                />
              </ChartWrapper>
              {/*Check if we can reuse this if not lets create simple component*/}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text fontSize={'base'} fontWeight={'semi'} lineHeight={'base'} color={theme.v2.text.disabled}>
                  {textTranslation('offering.sharesRemaining')}
                </Text>
              </div>
            </BondsRemainingContainer>
          </BondsInfoElementsContainer>
          <ButtonsWrapper>
            <Button
              text={textTranslation('offering.viewDetails')}
              variant={'outlined'}
              fill
              size={'medium'}
              color={'neutral'}
              onClick={() => navigate(`${routes.offering.smartShares.root}/${share.id}`)}
            />
            <Button
              text={textTranslation('offering.investNow')}
              fill
              size={'medium'}
              onClick={handleInvestButtonClick}
            />
          </ButtonsWrapper>
        </BondInfoWrapper>
      </BondInfoContainer>
    </BaseCard>
  );
};
