import React from 'react';

import { IconProps } from './iconProps';

export const CollectibleIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill={color}>
      <path
        d="M20 90H80C85.5228 90 90 85.2872 90 79.4737V20.5263C90 14.7128 85.5228 10 80 10H20C14.4772 10 10 14.7128 10 20.5263V79.4737C10 85.2872 14.4772 90 20 90Z"
        stroke={strokeColor}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 64.9995H75L58.3333 35.8328L45.8333 54.5828L37.5 46.2495L25 64.9995Z"
        stroke={strokeColor}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
