import { FC } from 'react';

import { IconProps } from './iconProps';

export const TrendUpCollectibleIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#A752E0' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 140 140" fill="none">
      <rect width="100%" height="100%" rx="10" fill={color} />
      <path
        d="M40 110H100C105.523 110 110 105.287 110 99.4737V40.5263C110 34.7128 105.523 30 100 30H40C34.4772 30 30 34.7128 30 40.5263V99.4737C30 105.287 34.4772 110 40 110Z"
        stroke={strokeColor}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45 84.9995H95L78.3333 55.8328L65.8333 74.5828L57.5 66.2495L45 84.9995Z"
        stroke={strokeColor}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
