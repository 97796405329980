import { FC } from 'react';

import { IconProps } from './iconProps';

export const PortfolioCollectibleIcon: FC<IconProps> = ({ color = 'none', strokeColor = 'white' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 226 118" fill={color}>
      <g opacity="0.1">
        <path
          d="M20.2866 64.6115L144.598 110.045C156.04 114.227 168.885 107.853 173.287 95.8078L217.923 -26.3225C222.325 -38.3673 216.618 -51.5217 205.175 -55.7037L80.864 -101.137C69.4215 -105.319 56.5769 -98.9448 52.1748 -86.9L7.53876 35.2303C3.13665 47.2751 8.84404 60.4295 20.2866 64.6115Z"
          stroke={strokeColor}
          strokeWidth="12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.5767 16.6003L153.169 54.4612L140.724 -18.5882L100.628 10.7938L89.6727 -12.7818L49.5767 16.6003Z"
          stroke={strokeColor}
          strokeWidth="12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
